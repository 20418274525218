@font-face {
  font-family: 'GT-Walsheim';
  src: url('../fonts/GT-font/gt-walsheim-regular-webfont.woff2') format('woff2'), url('../fonts/GT-font/gt-walsheim-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: url('../fonts/GT-font/gt-walsheim-regular-oblique-webfont.woff2') format('woff2'), url('../fonts/GT-font/gt-walsheim-regular-oblique-webfont.woff') format('woff');
  font-weight: 400;
  font-style: oblique;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: url('../fonts/GT-font/gt-walsheim-medium-webfont.woff2') format('woff2'), url('../fonts/GT-font/gt-walsheim-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: url('../fonts/GT-font/gt-walsheim-medium-oblique-webfont.woff2') format('woff2'), url('../fonts/GT-font/gt-walsheim-medium-oblique-webfont.woff') format('woff');
  font-weight: 500;
  font-style: oblique;
}