// colors
$fa-purple: #8088ae;
$fa-blue-dark: #000a46;
$fa-blue-dark-alt: #182258;
$fa-blue: #2755c4;
$fa-blue-2: #6491fd;
$fa-blue-light: #8EA5DC;
$fa-gray: #73778d;
$fa-gray-lines: #9e9ea0;
$fa-gray-solid: #d4d6e0;
$fa-gray-light: #f5f5f5;
$fa-gray-lighter: #DADBE5;
$fa-gray-pale: #fcfcfc;
$fa-gray-dark: #3b3b3b;
$fa-orange: #f93434;
$fa-yellow: #f8db1c;
$fa-red: #F83131;
$fa-white: #ffffff;
$fa-green: #00b291;
$fa-green-2: #23bda0;
$fa-black: #000;
$red-1: #ed001d;
$fa-periwinkle: #d4d6e0;
$grey-1: #606060;

$color-1: rgba(30, 42, 111, 0.5);

$link-color: $fa-blue;

$primary: $fa-blue-dark !default;
$secondary: $fa-blue !default;
$light: $fa-gray !default;

//fonts
$font-family: 'GT-Walsheim', arial, sans-serif;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$font-larger: 1.25rem;
$font-large: 1.125rem;
$font-base: 1rem;
$font-small: 0.875rem;
$font-smaller: 0.75rem;
$font-tiny: 0.625rem;

//Form
$label-margin-bottom : 13px !default;
$input-padding-y: 7px !default;
$input-padding-x: 16px !default;
$input-bg: $fa-gray-pale !default;
$input-color: #182258 !default;
$input-border-color: $fa-gray-solid !default;
$input-border-radius: 0 !default;

$input-focus-bg: $input-bg !default;
$input-focus-border-color: $fa-gray-solid !default;
$input-focus-color: $input-color !default;
$input-focus-box-shadow: none !default;

//Buttons
$btn-border-radius: 25px !default;
$btn-padding-y: 9px !default;
$btn-padding-x: 22px !default;
$btn-font-weight: $font-weight-medium !default;
