.ssn {
  .ssn-holder {
    display: flex;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-size-adjust: 100%;
    input {
      color: inherit;
      font-family: GT-Walsheim, arial, sans-serif;
      font-size: 14px;
      width: 200px;
      height: 40px;
      border: solid 2px $fa-gray-solid;
      text-align: center;
      letter-spacing: 5px;
      outline: none;
      &::placeholder {
        color: inherit;
      }
      &:focus {
        border: solid 1px $fa-blue;
      }
    }
    &.invalid input {
      color: $fa-red;
      border-color: $fa-red;
    }
  }

  .validation-message {
    color: $fa-gray;
    font-family: GT-Walsheim, arial, sans-serif;
    font-size: $font-small;
    font-weight: $font-weight-normal;
    line-height: 1.82;
    &.invalid {
      color: $fa-red;
    }
  }
}
